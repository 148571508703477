/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-csspositionsticky-jpeg2000-jpegxr-objectfit-webp-setclasses !*/
! function(e, A, n) {
    function t(e, A) {
        return typeof e === A
    }

    function r() {
        var e, A, n, r, o, i, s;
        for (var a in y)
            if (y.hasOwnProperty(a)) {
                if (e = [], A = y[a], A.name && (e.push(A.name.toLowerCase()), A.options && A.options.aliases && A.options.aliases.length))
                    for (n = 0; n < A.options.aliases.length; n++) e.push(A.options.aliases[n].toLowerCase());
                for (r = t(A.fn, "function") ? A.fn() : A.fn, o = 0; o < e.length; o++) i = e[o], s = i.split("."), 1 === s.length ? Modernizr[s[0]] = r : (!Modernizr[s[0]] || Modernizr[s[0]] instanceof Boolean || (Modernizr[s[0]] = new Boolean(Modernizr[s[0]])), Modernizr[s[0]][s[1]] = r), w.push((r ? "" : "no-") + s.join("-"))
            }
    }

    function o(e) {
        var A = b.className,
            n = Modernizr._config.classPrefix || "";
        if (C && (A = A.baseVal), Modernizr._config.enableJSClass) {
            var t = new RegExp("(^|\\s)" + n + "no-js(\\s|$)");
            A = A.replace(t, "$1" + n + "js$2")
        }
        Modernizr._config.enableClasses && (A += " " + n + e.join(" " + n), C ? b.className.baseVal = A : b.className = A)
    }

    function i() {
        return "function" != typeof A.createElement ? A.createElement(arguments[0]) : C ? A.createElementNS.call(A, "http://www.w3.org/2000/svg", arguments[0]) : A.createElement.apply(A, arguments)
    }

    function s(e) {
        return e.replace(/([a-z])-([a-z])/g, function(e, A, n) {
            return A + n.toUpperCase()
        }).replace(/^-/, "")
    }

    function a(e, A) {
        if ("object" == typeof e)
            for (var n in e) Q(e, n) && a(n, e[n]);
        else {
            e = e.toLowerCase();
            var t = e.split("."),
                r = Modernizr[t[0]];
            if (2 == t.length && (r = r[t[1]]), "undefined" != typeof r) return Modernizr;
            A = "function" == typeof A ? A() : A, 1 == t.length ? Modernizr[t[0]] = A : (!Modernizr[t[0]] || Modernizr[t[0]] instanceof Boolean || (Modernizr[t[0]] = new Boolean(Modernizr[t[0]])), Modernizr[t[0]][t[1]] = A), o([(A && 0 != A ? "" : "no-") + t.join("-")]), Modernizr._trigger(e, A)
        }
        return Modernizr
    }

    function l(e, A) {
        return !!~("" + e).indexOf(A)
    }

    function f(e, A) {
        return function() {
            return e.apply(A, arguments)
        }
    }

    function u(e, A, n) {
        var r;
        for (var o in e)
            if (e[o] in A) return n === !1 ? e[o] : (r = A[e[o]], t(r, "function") ? f(r, n || A) : r);
        return !1
    }

    function c(e) {
        return e.replace(/([A-Z])/g, function(e, A) {
            return "-" + A.toLowerCase()
        }).replace(/^ms-/, "-ms-")
    }

    function d(A, n, t) {
        var r;
        if ("getComputedStyle" in e) {
            r = getComputedStyle.call(e, A, n);
            var o = e.console;
            if (null !== r) t && (r = r.getPropertyValue(t));
            else if (o) {
                var i = o.error ? "error" : "log";
                o[i].call(o, "getComputedStyle returning null, its possible modernizr test results are inaccurate")
            }
        } else r = !n && A.currentStyle && A.currentStyle[t];
        return r
    }

    function p() {
        var e = A.body;
        return e || (e = i(C ? "svg" : "body"), e.fake = !0), e
    }

    function g(e, n, t, r) {
        var o, s, a, l, f = "modernizr",
            u = i("div"),
            c = p();
        if (parseInt(t, 10))
            for (; t--;) a = i("div"), a.id = r ? r[t] : f + (t + 1), u.appendChild(a);
        return o = i("style"), o.type = "text/css", o.id = "s" + f, (c.fake ? c : u).appendChild(o), c.appendChild(u), o.styleSheet ? o.styleSheet.cssText = e : o.appendChild(A.createTextNode(e)), u.id = f, c.fake && (c.style.background = "", c.style.overflow = "hidden", l = b.style.overflow, b.style.overflow = "hidden", b.appendChild(c)), s = n(u, e), c.fake ? (c.parentNode.removeChild(c), b.style.overflow = l, b.offsetHeight) : u.parentNode.removeChild(u), !!s
    }

    function m(A, t) {
        var r = A.length;
        if ("CSS" in e && "supports" in e.CSS) {
            for (; r--;)
                if (e.CSS.supports(c(A[r]), t)) return !0;
            return !1
        }
        if ("CSSSupportsRule" in e) {
            for (var o = []; r--;) o.push("(" + c(A[r]) + ":" + t + ")");
            return o = o.join(" or "), g("@supports (" + o + ") { #modernizr { position: absolute; } }", function(e) {
                return "absolute" == d(e, null, "position")
            })
        }
        return n
    }

    function h(e, A, r, o) {
        function a() {
            u && (delete T.style, delete T.modElem)
        }
        if (o = t(o, "undefined") ? !1 : o, !t(r, "undefined")) {
            var f = m(e, r);
            if (!t(f, "undefined")) return f
        }
        for (var u, c, d, p, g, h = ["modernizr", "tspan", "samp"]; !T.style && h.length;) u = !0, T.modElem = i(h.shift()), T.style = T.modElem.style;
        for (d = e.length, c = 0; d > c; c++)
            if (p = e[c], g = T.style[p], l(p, "-") && (p = s(p)), T.style[p] !== n) {
                if (o || t(r, "undefined")) return a(), "pfx" == A ? p : !0;
                try {
                    T.style[p] = r
                } catch (v) {}
                if (T.style[p] != g) return a(), "pfx" == A ? p : !0
            } return a(), !1
    }

    function v(e, A, n, r, o) {
        var i = e.charAt(0).toUpperCase() + e.slice(1),
            s = (e + " " + _.join(i + " ") + i).split(" ");
        return t(A, "string") || t(A, "undefined") ? h(s, A, r, o) : (s = (e + " " + x.join(i + " ") + i).split(" "), u(s, A, n))
    }
    var w = [],
        y = [],
        B = {
            _version: "3.6.0",
            _config: {
                classPrefix: "",
                enableClasses: !0,
                enableJSClass: !0,
                usePrefixes: !0
            },
            _q: [],
            on: function(e, A) {
                var n = this;
                setTimeout(function() {
                    A(n[e])
                }, 0)
            },
            addTest: function(e, A, n) {
                y.push({
                    name: e,
                    fn: A,
                    options: n
                })
            },
            addAsyncTest: function(e) {
                y.push({
                    name: null,
                    fn: e
                })
            }
        },
        Modernizr = function() {};
    Modernizr.prototype = B, Modernizr = new Modernizr;
    var Q, b = A.documentElement,
        C = "svg" === b.nodeName.toLowerCase();
    ! function() {
        var e = {}.hasOwnProperty;
        Q = t(e, "undefined") || t(e.call, "undefined") ? function(e, A) {
            return A in e && t(e.constructor.prototype[A], "undefined")
        } : function(A, n) {
            return e.call(A, n)
        }
    }(), B._l = {}, B.on = function(e, A) {
        this._l[e] || (this._l[e] = []), this._l[e].push(A), Modernizr.hasOwnProperty(e) && setTimeout(function() {
            Modernizr._trigger(e, Modernizr[e])
        }, 0)
    }, B._trigger = function(e, A) {
        if (this._l[e]) {
            var n = this._l[e];
            setTimeout(function() {
                var e, t;
                for (e = 0; e < n.length; e++)(t = n[e])(A)
            }, 0), delete this._l[e]
        }
    }, Modernizr._q.push(function() {
        B.addTest = a
    }), Modernizr.addAsyncTest(function() {
        var e = new Image;
        e.onload = e.onerror = function() {
            a("jpeg2000", 1 == e.width)
        }, e.src = "data:image/jp2;base64,/0//UQAyAAAAAAABAAAAAgAAAAAAAAAAAAAABAAAAAQAAAAAAAAAAAAEBwEBBwEBBwEBBwEB/1IADAAAAAEAAAQEAAH/XAAEQED/ZAAlAAFDcmVhdGVkIGJ5IE9wZW5KUEVHIHZlcnNpb24gMi4wLjD/kAAKAAAAAABYAAH/UwAJAQAABAQAAf9dAAUBQED/UwAJAgAABAQAAf9dAAUCQED/UwAJAwAABAQAAf9dAAUDQED/k8+kEAGvz6QQAa/PpBABr994EAk//9k="
    }), Modernizr.addAsyncTest(function() {
        var e = new Image;
        e.onload = e.onerror = function() {
            a("jpegxr", 1 == e.width, {
                aliases: ["jpeg-xr"]
            })
        }, e.src = "data:image/vnd.ms-photo;base64,SUm8AQgAAAAFAAG8AQAQAAAASgAAAIC8BAABAAAAAQAAAIG8BAABAAAAAQAAAMC8BAABAAAAWgAAAMG8BAABAAAAHwAAAAAAAAAkw91vA07+S7GFPXd2jckNV01QSE9UTwAZAYBxAAAAABP/gAAEb/8AAQAAAQAAAA=="
    }), Modernizr.addAsyncTest(function() {
        function e(e, A, n) {
            function t(A) {
                var t = A && "load" === A.type ? 1 == r.width : !1,
                    o = "webp" === e;
                a(e, o && t ? new Boolean(t) : t), n && n(A)
            }
            var r = new Image;
            r.onerror = t, r.onload = t, r.src = A
        }
        var A = [{
                uri: "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=",
                name: "webp"
            }, {
                uri: "data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAABBxAR/Q9ERP8DAABWUDggGAAAADABAJ0BKgEAAQADADQlpAADcAD++/1QAA==",
                name: "webp.alpha"
            }, {
                uri: "data:image/webp;base64,UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA",
                name: "webp.animation"
            }, {
                uri: "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=",
                name: "webp.lossless"
            }],
            n = A.shift();
        e(n.name, n.uri, function(n) {
            if (n && "load" === n.type)
                for (var t = 0; t < A.length; t++) e(A[t].name, A[t].uri)
        })
    });
    var E = "Moz O ms Webkit",
        _ = B._config.usePrefixes ? E.split(" ") : [];
    B._cssomPrefixes = _;
    var S = function(A) {
        var t, r = U.length,
            o = e.CSSRule;
        if ("undefined" == typeof o) return n;
        if (!A) return !1;
        if (A = A.replace(/^@/, ""), t = A.replace(/-/g, "_").toUpperCase() + "_RULE", t in o) return "@" + A;
        for (var i = 0; r > i; i++) {
            var s = U[i],
                a = s.toUpperCase() + "_" + t;
            if (a in o) return "@-" + s.toLowerCase() + "-" + A
        }
        return !1
    };
    B.atRule = S;
    var x = B._config.usePrefixes ? E.toLowerCase().split(" ") : [];
    B._domPrefixes = x;
    var U = B._config.usePrefixes ? " -webkit- -moz- -o- -ms- ".split(" ") : ["", ""];
    B._prefixes = U, Modernizr.addTest("csspositionsticky", function() {
        var e = "position:",
            A = "sticky",
            n = i("a"),
            t = n.style;
        return t.cssText = e + U.join(A + ";" + e).slice(0, -e.length), -1 !== t.position.indexOf(A)
    });
    var k = {
        elem: i("modernizr")
    };
    Modernizr._q.push(function() {
        delete k.elem
    });
    var T = {
        style: k.elem.style
    };
    Modernizr._q.unshift(function() {
        delete T.style
    }), B.testAllProps = v;
    var j = B.prefixed = function(e, A, n) {
        return 0 === e.indexOf("@") ? S(e) : (-1 != e.indexOf("-") && (e = s(e)), A ? v(e, A, n) : v(e, "pfx"))
    };
    Modernizr.addTest("objectfit", !!j("objectFit"), {
        aliases: ["object-fit"]
    }), r(), o(w), delete B.addTest, delete B.addAsyncTest;
    for (var D = 0; D < Modernizr._q.length; D++) Modernizr._q[D]();
    e.Modernizr = Modernizr
}(window, document);